import React from 'react';
import { Link } from 'react-router-dom';
import SmsIcon from '@material-ui/icons/Sms';
import {
  Button
} from 'react-admin';

export default function TenantVoiceRouteMenu({ record, lineId }) {
  return (
    <span>{record && (
      <Button
        component={Link}
        to={`/line_did_tenant_voice_routes/create?line_id=${lineId}&did_id=${record.id}&number=${encodeURIComponent(record.number)}`}
        label="VoiceRoute"
        title="VoiceRoute"
      >
        <SmsIcon />
      </Button>
    )}</span>
  )
}
