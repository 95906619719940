import * as React from 'react';
import {
  TextField,
  ArrayField,
  ReferenceField,
  Datagrid,
  BooleanField,
  DateField
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import CloseButton from '../voicemails/CloseButton';

const CloseVoiceRoute = ({ record, lineId, didId }) => {

  if (record.end_at !== null) {
    return (<span />);
  }

  return (<CloseButton label="Close" reference="tenant_voice_routes" record={record} lineId={lineId} didId={didId} />);

}

const LineDidInfos = ({ record, lineId }) => {
  return (
    <React.Fragment>
      <Typography variant="h6" style={{ marginBottom: '1em' }}>Voicemails</Typography>
      <ArrayField source="voicemails" addLabel={true} label="Voicemails">
        <Datagrid>
          <TextField source="email" />
          <TextField source="fullname" />
          <TextField source="greeting_language" />
          <TextField source="language" />
          <BooleanField source="are_recordings_sent_to_email" />
          <BooleanField source="are_recordings_saved_on_server" />
          <DateField source="start_at" showTime />
          <DateField source="end_at" showTime />
        </Datagrid>
      </ArrayField>
      <Typography variant="h6" style={{ marginBottom: '1em', marginTop: '1em' }}>Ringback Tones</Typography>
      <ArrayField source="ringback_tones" addLabel={true} label="Ringback Tones">
        <Datagrid>
          <TextField source="file_md5" />
          <DateField source="start_at" showTime />
          <DateField source="end_at" showTime />
        </Datagrid>
      </ArrayField>
      <Typography variant="h6" style={{ marginBottom: '1em', marginTop: '1em' }}>SMS Forwarder</Typography>
      <ArrayField source="sms_forwarder_settings" addLabel={true} label="SMS Forwarder" style={{ marginBottom: '1em' }}>
        <Datagrid>
          <TextField source="email_recipient_address" />
          <TextField source="email_sender_address" />
          <TextField source="email_sender_name" />
          {process.env.REACT_APP_DISPLAY_TENANT === "1" && (
            <TextField source="telegram_chat_id" />
          )}
          <DateField source="start_at" showTime />
          <DateField source="end_at" showTime />
        </Datagrid>
      </ArrayField>
      <Typography variant="h6" style={{ marginBottom: '1em', marginTop: '1em' }}>SMPP Routes</Typography>
      <ArrayField source="tenant_smpp_routes" addLabel={true} label="SMPP Routes" style={{ marginBottom: '1em' }}>
        <Datagrid>
          <ReferenceField label="Smpp Route" source="tenant_smpp_route_id" reference="tenant_smpp_routes" link={false}>
            <TextField source="smpp_route" />
          </ReferenceField>
          <DateField source="start_at" showTime />
          <DateField source="end_at" showTime />
        </Datagrid>
      </ArrayField>
      <Typography variant="h6" style={{ marginBottom: '1em', marginTop: '1em' }}>Voice Routes</Typography>
      <ArrayField source="tenant_voice_routes" addLabel={true} label="Voice Routes" style={{ marginBottom: '1em' }}>
        <Datagrid>
          <ReferenceField label="Voice Route" source="tenant_voice_route_id" reference="tenant_voice_routes" link={false}>
            <TextField source="voice_route" />
          </ReferenceField>
          <DateField source="start_at" showTime />
          <DateField source="end_at" showTime />
          <CloseVoiceRoute lineId={lineId} didId={record.id} />
        </Datagrid>
      </ArrayField>
    </React.Fragment>
  )
}

export default LineDidInfos;