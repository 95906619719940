import React from 'react';
import { Link } from 'react-router-dom';
import SmsIcon from '@material-ui/icons/Sms';
import {
  Button
} from 'react-admin';
import CloseButton from '../voicemails/CloseButton';
import find from 'lodash/find';


export default function TenantSmppRouteMenu({ record, lineId }) {
  const { tenant_smpp_routes } = record;
  let TenantSmppRouteSetting = find(tenant_smpp_routes, { end_at: null });

  if (record.end_at !== null) {
    return (<span />);
  }

  if (TenantSmppRouteSetting) {
    return (
      <span>
        <CloseButton label="SmppRoute" reference="tenant_smpp_routes" record={TenantSmppRouteSetting} lineId={lineId} didId={record.id} />
      </span>
    );
  }

  return (
    <span>{record && (
      <Button
        component={Link}
        to={`/line_did_tenant_smpp_routes/create?line_id=${lineId}&did_id=${record.id}&number=${encodeURIComponent(record.number)}`}
        label="SmppRoute"
        title="SmppRoute"
      >
        <SmsIcon />
      </Button>
    )}</span>
  )
}
